import styled from 'styled-components';

export const TitleDeleteQuestion = styled.p`
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 0;
`;

export const LabelCardQuestion = styled.p`
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 5px;
`;

export const DescriptionCardQuestion = styled.p`
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 300;
    margin-top: 0;
`;
