import { Theme } from 'app/shared/utils';
import styled from 'styled-components';

export const TitleDeleteQuestion = styled.p`
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
`;

export const LabelCreateQuestion = styled.label`
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 700;
    color: ${Theme.colors.black};
`;
