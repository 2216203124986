import styled from "styled-components";

export const Container = styled.div`

    .MuiDialog-container {
        height: fit-content !important;
        overflow: auto;
        opacity: 0 !important;
    }

`