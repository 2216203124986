import { AccordionDetails, AccordionSummary, Box, Checkbox, Typography } from "@mui/material"
import { StyledAccordion } from "./styles"
import { FiArrowDown } from "react-icons/fi"
import { Theme } from "app/shared/utils"

const SelectQuestoes = ({ onChange, categorias = [], selecteds = [], respostas }) => {

    const handleParentClick = (categoria) => (e, checked) => {
        e.stopPropagation()
        const ids = categoria.perguntas
            .filter(pergunta => pergunta.resposta_texto_final === '1')
            .filter(pergunta => respostas?.[pergunta.id])
            .filter(pergunta => respostas?.[pergunta.id].answered)
            .map(pergunta => pergunta.id)
        onChange(checked, ids)
    }

    const handleQuestionClick = (perguntaId) => (e, checked) => {
        e.stopPropagation()
        onChange(checked, [perguntaId])
    }

    const getIsAllClick = (categoria) => {
        return categoria.perguntas.every(pergunta => selecteds.includes(pergunta.id))
    }

    const getIsSomeClick = (categoria) => {
        return categoria.perguntas.some(pergunta => selecteds.includes(pergunta.id))
    }

    const nenhumaResposta = (categoria) => {
        return categoria.perguntas.every(pergunta => !respostas?.[pergunta.id])
    }

    return (
        <>
            {categorias.map(categoria => {
                const isAllChecked = getIsAllClick(categoria)
                return (
                    <StyledAccordion key={categoria.id} disableGutters disabled={nenhumaResposta(categoria)} >
                        <AccordionSummary
                            expandIcon={<FiArrowDown />}
                        >
                            <Box display='flex' alignItems='center'>
                                <Checkbox
                                    indeterminate={getIsSomeClick(categoria) && !isAllChecked}
                                    checked={isAllChecked}
                                    disabled={nenhumaResposta(categoria)}
                                    onChange={handleParentClick(categoria)}
                                />
                                <Typography fontSize='14px' fontWeight='bold'>{categoria.nome}</Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: "0px" }}>
                            {categoria.perguntas.map(pergunta => {
                                return (
                                    <Box
                                        display='flex'
                                        width='100%'
                                        p="5px 0px"
                                        pl="50px"
                                        alignItems='center'
                                        key={`pergunta-${pergunta.id}`}
                                        borderTop={`1px solid ${Theme.colors.gray.ligth}`}
                                    >
                                        <Checkbox
                                            disabled={!respostas?.[pergunta.id]?.answered}
                                            checked={selecteds.includes(pergunta.id)}
                                            onChange={handleQuestionClick(pergunta.id)}
                                        />
                                        <Typography fontSize='14px'>
                                            {pergunta.titulo}
                                        </Typography>
                                    </Box>
                                )
                            })}
                        </AccordionDetails>
                    </StyledAccordion >
                )

            })}
        </>
    )
}

export default SelectQuestoes

