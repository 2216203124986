const Theme = {
  colors: {
    blue: {
      default: '#1976d2',
      dark: '#004AAD',
    },
    gray: {
      default: '#e3e4e6',
      ligth: '#d1d4d9',
      dark: '#8e9094',
    },
    black: "#1b1818d1",
    grenn: "#34C38F",
    red: "#E6533C",
    yellow: "#FBE15A",
    orange: "#EF6C00",
    purple: "#9C27B0",
    green: "#2E7D32",
  },
};

export default Theme;
