import { Typography } from '@mui/material';
import styled from 'styled-components';

export const TextCounter = styled(Typography)`
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 300;
    text-align: right;
    margin-top: 0;
    margin-bottom: 2px;
`;
