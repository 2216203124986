import { createSlice } from '@reduxjs/toolkit';
import { TextoPerguntaTypes } from 'app/shared/constants';
import { removeTags } from 'app/shared/utils';

const isAnswered = (pergunta, resposta) => {
    const isMultipleChoice = [
        TextoPerguntaTypes.RADIO,
        TextoPerguntaTypes.CHECKBOX
    ].includes(pergunta?.tipo)
    const isNumber = pergunta?.tipo === TextoPerguntaTypes.NUMBER

    if (isMultipleChoice || isNumber) {
        return String(resposta).length > 0
    }
    return removeTags(resposta).length > 0
}

const textoSlice = createSlice({
    name: 'texto',
    initialState: {
        selectedTexto: null,
        perguntas: [],
        respostas: {},
        filters: {
            genero_textual_id: -1,
            titulo: '',
            status: -1,
            page: 1
        }
    },
    reducers: {
        setTextoFiltro: (state, action) => {
            state.filters = { ...state.filters, ...action.payload }
        },
        setTexto: (state, action) => {
            const respostas = action.payload?.respostas || []
            const perguntas = action.payload.genero_textual.perguntas

            let formatedRespostas = {}
            respostas.forEach(resposta => {

                const pergunta = perguntas.find(pergunta => pergunta.id === resposta.pergunta_id)
                formatedRespostas[resposta.pergunta_id] = {
                    text: resposta.resposta,
                    respostaId: resposta.id,
                    checked: resposta.uso_texto_final ? resposta.uso_texto_final === '1' : true,
                    answered: isAnswered(pergunta, resposta.resposta),
                    num_palavras: Number(resposta.num_palavras)
                }
            })
            state.selectedTexto = action.payload
            state.respostas = formatedRespostas
        },
        setPerguntas: (state, action) => {
            const perguntas = action.payload
            state.perguntas = perguntas
        },
        setResposta: (state, action) => {
            const perguntaId = action.payload.perguntaId
            const resposta = action.payload.resposta
            const resposta_texto_final = action.payload.resposta_texto_final === '1'

            const checked = resposta.uso_texto_final ? resposta.uso_texto_final === '1' : true

            const pergunta = state.perguntas.find(pergunta => pergunta.id === perguntaId)

            state.respostas[perguntaId] = {
                text: resposta.resposta,
                respostaId: resposta.id,
                checked: resposta_texto_final && checked,
                answered: isAnswered(pergunta, resposta.resposta),
                num_palavras: Number(resposta.num_palavras)
            }
        },
        updateUsoFinalRespostas: (state, action) => {
            const perguntasIds = action.payload.perguntasIds
            const checked = action.payload.checked
            perguntasIds.forEach(id => {
                state.respostas[id].checked = checked
            })

        },
        updateTextoFinal: (state, action) => {
            state.selectedTexto.texto = action.payload.texto
            state.selectedTexto.num_palavras = action.payload.num_palavras
            state.selectedTexto.updated_at = action.payload.updated_at
        },
        clearCreationState: (state, action) => {
            state.selectedTexto = null
            state.perguntas = []
            state.respostas = {}
        },
    },
});

export const { setTextoFiltro, setTexto, setPerguntas, setResposta, clearCreationState, updateUsoFinalRespostas, updateTextoFinal } = textoSlice.actions;
export default textoSlice.reducer;