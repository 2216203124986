import { Card } from '@mui/material';
import styled from 'styled-components';

export const CardDashboard = styled(Card)`
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    border: none;
    margin-bottom: 15px;
`;

export const CardDashboardValue = styled.p`
    font-family: 'Roboto';
    font-size: 34px;
    font-weight: 400;
    line-height: 41.99px;
    letter-spacing: 0.25px;
    text-align: left;
    padding: 0;
    margin: 0;
    align-self: center;
`;

export const CardDashboardTitle = styled.p`
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 400;
    line-height: 29.64px;
    letter-spacing: 0.25px;
    text-align: left;
    align-self: center;
    padding: 0;
    margin: 0;
`;

export const TitleDashboard = styled.p`
    font-family: 'Roboto';
    font-size: 34px;
    font-weight: 400;
    letter-spacing: 0.25px;
    text-align: left;
    margin: 0;
`;

export const DateDashboard = styled.p`
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
    margin: 0;
    margin-bottom: 20px;
`;
